import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [0,5,6];

export const dictionary = {
		"/(external)/appointments/[id]/cancel": [~18,[4]],
		"/(frontend)/benutzer": [~36,[5]],
		"/(external)/booking/danke": [20,[4]],
		"/(external)/booking/fehler": [21,[4]],
		"/(external)/booking/[location]": [~19,[4]],
		"/(external)/datenschutz": [22,[4]],
		"/(frontend)/(content-routes)/detail/client/[id]/appointment-history": [~24,[5]],
		"/(development)/dev": [11,[3]],
		"/(development)/dev/buttons": [12,[3]],
		"/(development)/dev/cards": [13,[3]],
		"/(development)/dev/common": [14,[3]],
		"/(development)/dev/icons": [15,[3]],
		"/(development)/dev/inputs": [16,[3]],
		"/(development)/dev/modals": [17,[3]],
		"/(frontend)/(content-routes)/forms/appointment/[[id]]": [~25,[5]],
		"/(frontend)/(content-routes)/forms/client/[[id]]": [~26,[5]],
		"/(frontend)/(content-routes)/forms/presence/[resourceId]": [~27,[5]],
		"/(frontend)/(content-routes)/forms/resource/[[id]]": [~28,[5]],
		"/(frontend)/(content-routes)/forms/search": [29,[5]],
		"/(frontend)/(content-routes)/forms/service-type/[[id]]": [~30,[5]],
		"/(frontend)/(content-routes)/forms/settings": [~31,[5]],
		"/(frontend)/(content-routes)/forms/user/[[id]]": [~32,[5]],
		"/(external)/impressum": [23,[4]],
		"/(authentication)/login": [~7,[2]],
		"/(authentication)/logout": [~8,[2]],
		"/(authentication)/password-forgot": [~9,[2]],
		"/(authentication)/password-reset/[token]": [~10,[2]],
		"/(frontend)/patienten": [~37,[5]],
		"/sentry-example": [38],
		"/unauthorized": [39],
		"/(frontend)/[location]/kalender": [~33,[5,6]],
		"/(frontend)/[location]/raeume": [~34,[5,6]],
		"/(frontend)/[location]/terminarten": [~35,[5,6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';